import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

export const fragment = graphql`
  fragment ContactIntroFragment on WordPress_Page_Contactfields {
    contactDetails {
      items {
        title
        email
        phone
      }
      address
    }
  }
`

const Intro = ({ items, address }) => (
  <Outer>
    {items.map((item, i) => (
      <ContactItem key={i}>
        <h2>{item.title}</h2>
        <p>
          <a href={`mailto:${item.email}`}>{item.email}</a>
        </p>
        <p>{item.phone}</p>
      </ContactItem>
    ))}

    <ContactAddress>
      <h2>{address}</h2>
    </ContactAddress>
  </Outer>
)

const Outer = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  background-color: ${props => props.theme.colours.darkGrey};
  padding: 17rem 16.5%;

  @media (max-width: 1260px) {
    padding: 100px 12.5%;
  }

  @media (max-width: 650px) {
    padding: 65px 20px;
  }
`

const ContactItem = styled.div`
  width: 48%;
  margin-bottom: 7.5rem;

  @media (max-width: 767px) {
    width: 100%;
    margin-bottom: 50px;
  }

  h2 {
    color: ${props => props.theme.colours.white};
    font-size: 4.2rem;
    font-family: ${props => props.theme.fonts.butler};
    margin-bottom: 4.5rem;
    letter-spacing: -0.1rem;

    @media (max-width: 767px) {
      font-size: 2.6rem;
      margin-bottom: 1.5rem;
    }
  }

  p {
    color: ${props => props.theme.colours.white};
    font-family: ${props => props.theme.fonts.maison};
    font-weight: 200;
    letter-spacing: 0.1rem;
    line-height: 1;

    @media (max-width: 767px) {
      font-size: 1.9rem;
    }
  }

  a {
    color: #fff;
    transition: color 0.8s ease;
    text-decoration: none;

    &:hover {
      color: ${props => props.theme.colours.orange};
    }
  }
`

const ContactAddress = styled.div`
  width: 48%;

  @media (max-width: 767px) {
    width: 62%;
  }

  h2 {
    color: ${props => props.theme.colours.orange};
    font-size: 4.2rem;
    font-family: ${props => props.theme.fonts.butler};
    margin-bottom: 5rem;
    line-height: 1.33;
    white-space: pre-wrap;
    letter-spacing: -0.1rem;

    @media (max-width: 767px) {
      font-size: 3.2rem;
      margin-bottom: 0;
    }
  }
`

export default Intro
