import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Hero from '../components/hero'
import Intro from '../components/contact/intro'
import Map from '../components/contact/map'
import Seo from '../components/seo'

export const fragment = graphql`
  fragment ContactHeroFragment on WordPress_Page_Contactfields {
    hero {
      title
      imageCaption
      imageBack {
        focalPoint
        focalPointMobile
        image {
          altText
          sourceUrl
          mediaItemId
          modified
          id
          imageFile {
            childImageSharp {
              fluid(maxWidth: 2000, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
      imageFront {
        focalPoint
        focalPointMobile
        image {
          altText
          sourceUrl
          mediaItemId
          modified
          id
          imageFile {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  }
`

const Contact = ({ data, pageContext }) => (
  <Layout>
    <Seo pageTitle={pageContext.title} {...data.wordPress.pageBy.seo} />
    <Hero {...data.wordPress.pageBy.contactFields.hero} />
    <Intro {...data.wordPress.pageBy.contactFields.contactDetails} />
    <Map {...data.wordPress.pageBy.contactFields.map} />
  </Layout>
)

export default Contact

export const query = graphql`
  query($wordpressId: Int!) {
    wordPress {
      pageBy(pageId: $wordpressId) {
        ...SeoFragment
        contactFields {
          ...ContactHeroFragment
          ...ContactIntroFragment
          ...ContactMapFragment
        }
      }
    }
  }
`
